import { pluginVideo } from 'wix-rich-content-plugin-video/dist/module.viewer.cjs';
import { pluginDivider } from 'wix-rich-content-plugin-divider/dist/module.viewer.cjs';
import { pluginImage } from 'wix-rich-content-plugin-image/dist/module.viewer.cjs';
import { pluginGallery } from 'wix-rich-content-plugin-gallery/dist/module.viewer.cjs';
import { pluginLink } from 'wix-rich-content-plugin-link/dist/module.viewer.cjs';
import { pluginGiphy } from 'wix-rich-content-plugin-giphy/dist/module.viewer.cjs';
import { pluginHtml } from 'wix-rich-content-plugin-html/dist/module.viewer.cjs';
import { pluginFileUpload } from 'wix-rich-content-plugin-file-upload/dist/module.viewer.cjs';
import { pluginLinkPreview } from 'wix-rich-content-plugin-link-preview/dist/module.viewer.cjs';
import {
  pluginTextColor,
  pluginTextHighlight,
} from 'wix-rich-content-plugin-text-color/dist/module.viewer.cjs';
import { pluginVerticalEmbed } from 'wix-rich-content-plugin-vertical-embed/dist/module.viewer.cjs';
import { pluginTable } from 'wix-rich-content-plugin-table/dist/module.viewer.cjs';
import { pluginSoundCloud } from 'wix-rich-content-plugin-sound-cloud/dist/module.viewer.cjs';

const PLUGINS = [
  pluginVideo({ disableDownload: true }),
  pluginDivider(),
  pluginImage(),
  pluginGallery(),
  pluginLink(),
  pluginGiphy(),
  pluginHtml(),
  pluginFileUpload(),
  pluginLinkPreview(),
  pluginTextColor(),
  pluginTextHighlight(),
  pluginVerticalEmbed(),
  pluginTable(),
  pluginSoundCloud(),
];

export const rceUtils = {
  PLUGINS,
};
