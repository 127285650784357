import React from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import {
  Button,
  PRIORITY as ButtonPRIORITY,
  SIZE as ButtonSIZE,
} from 'wix-ui-tpa/Button';
import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';
import { Challenges } from '../../../../../editor/types/Experiments';
import utils from '../../views/utils';
import { useChallengeData } from '../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';

import { classes } from './StepControls.st.css';
import { IControl } from './types';

export interface ISectionControls extends IControl {
  currentSectionId: string;
  onStepChosen(firstSectionStep: V1ParticipantStep): void;
}

export const SectionControls: React.FC<ISectionControls> = (props) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { isMobile } = useEnvironment();
  const {
    challengeData: { challenge },
  } = useChallengeData();

  const flatSteps = utils.getFlatStepsList({
    listParticipantSections: props.sections,
    participantSteps: props.steps,
  });

  const chosenSection = props.sections.find(
    (s) => s.id === props.currentSectionId,
  );
  const firstSectionStep = chosenSection?.steps?.[0];

  const isFirstSectionStepHidden =
    firstSectionStep &&
    utils.isStepHidden(
      challenge,
      flatSteps,
      firstSectionStep,
      experiments.enabled(Challenges.isHiddenStepsEnabled),
    );

  const nextEntity = utils.getNextEntity(firstSectionStep?.id, {
    listParticipantSections: props.sections,
    participantSteps: props.steps,
  });

  if (isFirstSectionStepHidden) {
    return null;
  }

  return (
    <div className={`${classes.root}`}>
      <Button
        className={classes.stepViewMainCTA}
        fullWidth={isMobile}
        priority={ButtonPRIORITY.primary}
        size={isMobile ? ButtonSIZE.small : ButtonSIZE.medium}
        onClick={async () => {
          if (firstSectionStep) {
            props.onStepChosen(firstSectionStep);
          } else {
            await props.onNextEntity(nextEntity);
          }
        }}
      >
        {t('challenge.page.steps.continue')}
      </Button>
    </div>
  );
};
