import React from 'react';
import { ChallengeReward } from '@wix/ambassador-challenge-service-web/types';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { loadBadges } from '../../services/BadgesService';
import { LocationContext } from '../../contexts/Location/LocationContext';
import { getSiteOrigin } from '../../contexts/Location/urlHelpers';

import { st, classes } from './Badges.st.css';
import { Spinner } from '../Spinner';

export interface IBadge {
  id: string;
  title: string;
  description: string;
  backgroundColor: string;
  textColor: string;
  icon: string;
  roleId: string;
  hasPermissions: boolean;
  slug: string;
  createDate: string;
  updateDate: string;
}

export enum ALIGNMENT {
  left = 'left',
  right = 'right',
  center = 'center',
}

export interface IBadgesProps {
  rewards: ChallengeReward[];
  instance: string;
  title?: React.ReactNode;
  alignment?: ALIGNMENT;
}

export const AwardsBadges: React.FC<IBadgesProps> = (props) => {
  const [badges, setBadges] = React.useState<IBadge[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { isPreview, isEditor } = useEnvironment();
  const { location } = React.useContext(LocationContext);

  const origin = isEditor || isPreview ? '' : getSiteOrigin(location.baseUrl);

  React.useEffect(() => {
    const challengeRewardsIds = props.rewards[0]?.badgeIds || null;

    if (!challengeRewardsIds) {
      return;
    }

    setLoading(true);

    const fetchBadges = async () => {
      try {
        const badgesRes = await loadBadges(
          props.instance,
          origin,
          challengeRewardsIds,
        );
        const filteredBadges = badgesRes.badges.filter((badge) =>
          challengeRewardsIds.includes(badge.id),
        );

        setBadges(filteredBadges);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchBadges();
  }, []);

  return (
    <div
      className={st(classes.root, { align: props.alignment || ALIGNMENT.left })}
    >
      {!!badges.length && props.title}
      {loading ? (
        <Spinner role="element" className={classes.spinner} />
      ) : (
        <div className={classes.badgeList}>
          {badges.map((badge, idx) => (
            <span
              key={`${badge.title}-${idx}`}
              className={classes.badge}
              style={{
                color: badge.textColor,
                background: badge.backgroundColor,
              }}
            >
              {!!badge.icon && (
                <img
                  className={classes.image}
                  src={badge.icon}
                  alt={badge.slug}
                />
              )}
              <span className={classes.text}>{badge.title}</span>
            </span>
          ))}
        </div>
      )}
      {}
    </div>
  );
};
