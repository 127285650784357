import React from 'react';
import { st, classes } from './SocialShare.st.css';
import { SocialBar } from 'wix-ui-tpa/SocialBar';
import { Icons } from 'wix-ui-tpa/dist/src';
import { CopyUrlButton } from 'wix-ui-tpa/CopyUrlButton';
import { ButtonNames } from '../../../../../contexts/BI/interfaces';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useMemberBI } from '../../../../../contexts/BI/BIContext';

export interface ISocialShareProps {
  shareTitle: string;
  shareUrl: string;
  originalUrl: string;
}

export interface ISocialShareState {}

export const SocialShare: React.FC<ISocialShareProps> = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { memberWebAppButtonClick } = useMemberBI();

  const { shareTitle, shareUrl, originalUrl } = props;

  return (
    <div
      className={st(classes.root, {
        mobile: isMobile,
      })}
    >
      <h2 className={classes.socialShareTitle}>
        {t('live.challenges-page.social-share.title')}
      </h2>

      <SocialBar>
        <SocialBar.Icon
          tooltip={t('live.challenges-page.social-share.facebook')}
          aria-label={t('live.challenges-page.social-share.facebook')}
          icon={<Icons.SocialIcons.Facebook />}
          href={`//facebook.com/sharer/sharer.php?u=${shareUrl}`}
          // @ts-expect-error
          target="_blank"
          onClick={() => {
            memberWebAppButtonClick({ buttonName: ButtonNames.ShareFacebook });
          }}
        />
        <SocialBar.Icon
          tooltip={t('live.challenges-page.social-share.twitter')}
          aria-label={t('live.challenges-page.social-share.twitter')}
          icon={<Icons.SocialIcons.Twitter />}
          href={`//twitter.com/intent/tweet?text=${shareTitle}&url=${originalUrl}`}
          // @ts-expect-error
          target="_blank"
          onClick={() => {
            memberWebAppButtonClick({ buttonName: ButtonNames.ShareTwitter });
          }}
        />
        <SocialBar.Icon
          tooltip={t('live.challenges-page.social-share.linkedin')}
          aria-label={t('live.challenges-page.social-share.linkedin')}
          icon={<Icons.SocialIcons.Linkedin />}
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`}
          // @ts-expect-error
          target="_blank"
          onClick={() => {
            memberWebAppButtonClick({ buttonName: ButtonNames.ShareLinkedin });
          }}
        />

        {originalUrl ? (
          <CopyUrlButton
            aria-label={t('live.challenges-page.social-share.copy-link')}
            tooltipText={t('live.challenges-page.social-share.copy-link')}
            successText={t(
              'live.challenges-page.social-share.copy-link-reaction',
            )}
            url={originalUrl}
            onClick={() => {
              memberWebAppButtonClick({ buttonName: ButtonNames.CopyLink });
            }}
          />
        ) : null}
      </SocialBar>
    </div>
  );
};

SocialShare.displayName = 'SocialShare';
