import React from 'react';

import { st, classes } from './ChallengeDetails.st.css';

export interface IChallengeDetails {
  details: string;
  className?: string;
  isMobile?: boolean;
}

const URL_SPLITTER_REGEXP = /((?:ftp|http|https):\/\/(?:\w+:{0,1}\w*@)?(?:\S+)(?::[0-9]+)?(?:\/|\/(?:[\w#!:.?+=&%@!-/]))?)/;
const IS_URL_REGEXP = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
const MAX_URL_LENGTH = 2048;

const splitByUrl = (str: string) => {
  const result = str.split(URL_SPLITTER_REGEXP);

  return result;
};

const isURL = (str: string) => {
  return str.length <= MAX_URL_LENGTH && IS_URL_REGEXP.test(str);
};

export const ChallengeDetails: React.FC<IChallengeDetails> = (props) => {
  const { details, className, isMobile } = props;
  if (!details) {
    return null;
  }

  const parsedDetails = splitByUrl(details);

  return (
    <p className={st(classes.root, { mobile: isMobile }, className)}>
      {parsedDetails.map((str) => {
        return isURL(str) ? (
          <a
            href={str}
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {str}
          </a>
        ) : (
          str
        );
      })}
    </p>
  );
};

ChallengeDetails.displayName = 'ChallengeDetails';
