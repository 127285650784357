import React from 'react';

import { StepsListAsTiles } from '../StepsListAsTiles';

import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import challengeSettings from '../../../settingsParams';
import { convertTextToAccordionAlignment } from '../../../../../services/settingsHelpers';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { SectionsAsTilesAccordion } from '../../../../../components-shared/SectionsAsTilesAccordion';

import { st, classes } from './SectionsListAsTiles.st.css';
import utils from '../../views/utils';
import { useSections } from '../../../../../contexts/ParticipantSections/ParticipantSectionsContext';

export interface ISectionsListAsTilesProps {
  currentStepId: string;
  onSectionChosen?: Function;
  chosenSectionTileId?: string;
  onStepChosen: Function;
}

export const SectionsListAsTiles: React.FunctionComponent<ISectionsListAsTilesProps> = (
  props: ISectionsListAsTilesProps,
) => {
  const { onSectionChosen, chosenSectionTileId } = props;
  const settings = useSettings();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const {
    isListParticipantSectionsRequestInProgress,
    listParticipantSections = [],
  } = useSections();

  if (isListParticipantSectionsRequestInProgress) {
    // TODO: add spinner;
    return null;
  }

  return (
    <div
      className={`${st(classes.root, {
        mobile: isMobile,
        contentTextAlignment: settings.get(
          challengeSettings.listLayoutContentAlignment,
        ),
      })}`}
    >
      <ul className={classes.sectionsList}>
        {listParticipantSections.map(
          (
            { id, source: { settings: sectionSettings }, steps = [] },
            ind: number,
          ) => {
            const key = `section-${id}`;
            const sectionSteps = steps || [];
            const resolvedLength = sectionSteps.filter((step) =>
              utils.isStepResolved(step),
            ).length;
            const isOpenSection = !!steps.find(
              (step) => step?.id === props.currentStepId,
            );
            const subtitle = t('live.challenges-page.section-steps-subtitle', {
              resolved: resolvedLength,
              total: sectionSteps.length,
            });

            const isActiveSection = chosenSectionTileId === id;

            return (
              <li key={`section-${ind}`} className={classes.sectionsListItem}>
                <SectionsAsTilesAccordion
                  id={key}
                  key={key}
                  className={isActiveSection ? classes.sectionActive : ''}
                  opened={isOpenSection || false}
                  title={sectionSettings?.description?.title}
                  subtitle={subtitle}
                  emptyChildren={!!steps.length}
                  align={convertTextToAccordionAlignment(
                    settings.get(challengeSettings.sidebarLayoutTextAlignment),
                  )}
                  onClick={() => {
                    if (onSectionChosen) {
                      onSectionChosen(id);
                    }
                  }}
                >
                  <StepsListAsTiles
                    currentStepId={props.currentStepId}
                    steps={sectionSteps}
                    isSPC={true}
                    onStepChosen={props.onStepChosen}
                  />
                </SectionsAsTilesAccordion>
              </li>
            );
          },
        )}
      </ul>
    </div>
  );
};
SectionsListAsTiles.displayName = 'SectionsListAsTiles';
