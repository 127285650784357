import React from 'react';

import { PageSection } from '../../../../../components-shared/PageSection';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { st, classes } from './ChallengeOverviewSection.st.css';
import utils from '../../views/utils';

interface IChallengeOverviewSectionProps {
  title?: string;
  dataHook?: string;
  className?: string;
  type: 'List' | 'Sidebar';
}

// todo: styles: common => title => content
// todo: mobile => alignment

export const ChallengeOverviewSection: React.FC<IChallengeOverviewSectionProps> = ({
  className,
  type,
  children,
  ...rest
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  return (
    <PageSection
      className={`${st(classes.root, {
        mobile: isMobile,
        type: type.toLowerCase(),
        alignment: utils.getContentAlignByType(type, settings),
      })} ${className}`}
      {...rest}
    >
      {children}
    </PageSection>
  );
};
