import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';
import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Tooltip } from 'wix-ui-tpa/Tooltip';
import { useIsStepHidden } from '../../../../../contexts/IsStepHidden/IsStepHiddenContext';
import { useUser } from '../../../../../contexts/User/UserContext';
import utils from '../../views/utils';

import { classes } from './StepsListAsTiles.st.css';

export interface IStepSidebar {
  step: V1ParticipantStep;
  isSPC: boolean;
  currentStepId: string;
  onStepChosen: Function;
}

export const StepSidebar: React.FC<IStepSidebar> = (props) => {
  const { t } = useTranslation();
  const { isSPC, currentStepId, onStepChosen, step } = props;
  const { isStepHidden, isVisibleStepLockedForComplete } = useIsStepHidden();
  const { participant } = useUser();

  const isStepUnavailable =
    isStepHidden(step) || isVisibleStepLockedForComplete(step);
  const Icon = utils.getStepIcon(step, isStepUnavailable, participant);
  const tooltip = t(
    utils.getStepTooltipKey(step, isStepUnavailable, isSPC, participant),
  );
  const isStepAvailableForComplete = utils.isStepAvailableForComplete(step);
  const isCurrent = step?.id === currentStepId;
  const tooltipDisabled =
    !utils.isParticipantCompletedChallenge(participant) &&
    isStepAvailableForComplete &&
    !isStepUnavailable;

  return (
    <li
      className={`${classes.stepItem} ${
        isCurrent ? `${classes.stepItemActive}` : ''
      }`}
      data-id={step?.id}
      key={step?.id}
      onClick={() => {
        onStepChosen(step);
      }}
    >
      <button className={classes.stepLink} onClick={() => {}}>
        {Icon ? (
          <Tooltip
            className={classes.stepItemIcon}
            content={tooltip}
            placement="top"
            appendTo="window"
            maxWidth={250}
            // do not show tooltip for available step
            disabled={tooltipDisabled}
          >
            <Icon />
          </Tooltip>
        ) : null}
        <div className={classes.gapBetweenTitleAndIcon} />
        <p className={`${classes.stepItemTitle}`}>
          {step?.source?.settings?.general?.description?.title}
        </p>
      </button>
    </li>
  );
};

StepSidebar.displayName = 'StepSidebarItem';
