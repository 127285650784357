import React from 'react';

import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { SettingsEventsContext } from '../../../../../contexts/SettingsEvents/SettingsEvents';
import { convertTextToAccordionAlignment } from '../../../../../services/settingsHelpers';
import challengeSettings from '../../../settingsParams';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { IsStepHiddenContext } from '../../../../../contexts/IsStepHidden/IsStepHiddenContext';
import { ChallengeDataContext } from '../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { isSelfPaced } from '../../../../../selectors/challenges';
import { ActionBlockerAppearance } from '../../../../../components-shared/ActionBlocker/ActionBlocker';
import { PreviewActionBlockerContainer } from '../../../../../components-shared/ActionBlocker/PreviewActionBlockerContainer';
import { useUser } from '../../../../../contexts/User/UserContext';
import useLocales from '../../../../../services/hooks/useLocales';
import format from 'date-fns/format';
import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';

import {
  Button,
  PRIORITY as ButtonPRIORITY,
  SIZE as ButtonSIZE,
} from 'wix-ui-tpa/Button';
import { StepsAccordion } from '../../../../../components-shared/StepsAccordion';
import { StepCompletedMessage } from '../StepCompletedMessage';
import { Ricos } from '../../../../../components-shared/Ricos';
import { StepViewHidden } from '../StepViewHidden';

import { classes, st } from './StepView.st.css';
import utils from '../../views/utils';

export interface IStepViewProps {
  t: Function;
  className?: string;

  step: V1ParticipantStep;
  isStepOpened: boolean;
  isStepForceOpened?: boolean;
  renderType?: 'AccordionTitle' | 'AccordionFull' | 'ClearStep';
  isLastStep?: boolean;

  isStepsLoading: boolean;
  isParticipantCompletedChallenge: boolean;

  isWeekFixForCTA?: boolean;

  onStepTitleClick?(id: string): void;

  onStepResolve?(step: V1ParticipantStep): void;

  onFeedbackView?(step: V1ParticipantStep): void;

  onShare?(step: V1ParticipantStep): void;

  onNextStepClick?(): void;

  goToCurrentStep?(): void;
}

const StepViewBody: React.FunctionComponent<IStepViewProps> = (
  props: IStepViewProps,
) => {
  const {
    t,
    step,
    isStepsLoading,
    isLastStep,
    isParticipantCompletedChallenge,
    onStepResolve,
    onFeedbackView,
    onShare,
    onNextStepClick,
  } = props;

  const { language } = useEnvironment();
  const { participant } = useUser();

  const isStepAvailableForComplete = utils.isStepAvailableForComplete(step);
  const isStepLocked =
    utils.isStepLocked(step) &&
    !utils.isParticipantCompletedChallenge(participant);
  const { isVisibleStepLockedForComplete } = React.useContext(
    IsStepHiddenContext,
  );
  const isCompleteButtonShown =
    isStepAvailableForComplete || utils.isStepOverdue(step);

  const { locales, isLocalesLoading } = useLocales();

  if (isLocalesLoading) {
    return null;
  }

  const formattedStepDate = format(new Date(step.dateFrame.start), 'PP', {
    locale: locales[language],
  });

  const CTAButton = isCompleteButtonShown ? (
    <PreviewActionBlockerContainer
      appearance={ActionBlockerAppearance.PopoverAppearance}
      content={t('action-blocker.preview-mode.complete-step')}
      isActionAllowed={utils.isFeedbackFormRequired(step)}
    >
      <Button
        className={classes.completeStepAction}
        disabled={isStepsLoading}
        priority={ButtonPRIORITY.primary}
        size={ButtonSIZE.small}
        onClick={() => {
          if (!isStepsLoading) {
            onStepResolve(step);
          }
        }}
      >
        {t('challenge.page.steps.complete')}
      </Button>
    </PreviewActionBlockerContainer>
  ) : (
    <Button
      className={classes.completeStepAction}
      priority={ButtonPRIORITY.primary}
      size={ButtonSIZE.small}
      onClick={onNextStepClick}
    >
      {isLastStep
        ? t('live.challenges-page.back-from-step-view')
        : t('challenge.page.next-step')}
    </Button>
  );

  return (
    <>
      {isStepLocked ? (
        <p className={classes.stepUnavailable}>
          {t('challenge.page.step-unavailable.warning', {
            date: formattedStepDate,
          })}
        </p>
      ) : null}

      {utils.isStepResolved(step) ? (
        <StepCompletedMessage
          t={t}
          step={step}
          isStepsLoading={isStepsLoading}
          onFeedbackView={onFeedbackView}
          onShare={onShare}
        />
      ) : null}

      <Ricos
        key={step?.id}
        details={step?.source?.settings?.general?.description?.details}
      />

      {!isVisibleStepLockedForComplete(step) && !isParticipantCompletedChallenge
        ? CTAButton
        : null}
    </>
  );
};
StepViewBody.displayName = 'StepViewBody';

export const StepView: React.FunctionComponent<IStepViewProps> = (
  props: IStepViewProps,
) => {
  const {
    t,
    className,
    step,
    isStepOpened,
    isStepForceOpened,
    renderType,
    isWeekFixForCTA,
    onStepTitleClick,
    goToCurrentStep,
  } = props;

  const { isMobile } = useEnvironment();
  const { participant } = useUser();
  const { buttonState } = React.useContext(SettingsEventsContext);
  const settings = useSettings();
  const { isStepHidden, isVisibleStepLockedForComplete } = React.useContext(
    IsStepHiddenContext,
  );
  const {
    challengeData: { challenge },
  } = React.useContext(ChallengeDataContext);

  React.useEffect(() => {
    utils.scrollToChallengePage();
  }, []);

  const title = step?.source?.settings?.general?.description?.title;

  const isStepAvailableForComplete = utils.isStepAvailableForComplete(step);
  const isHidden = isStepHidden(step);
  const isUnavailable = isHidden || isVisibleStepLockedForComplete(step);

  const tooltip = t(
    utils.getStepTooltipKey(
      step,
      isUnavailable,
      isSelfPaced(challenge),
      participant,
    ),
  );
  const Icon = utils.getStepIcon(step, isUnavailable, participant);

  const isAccordion =
    renderType === 'AccordionTitle' || renderType === 'AccordionFull';
  const isAccordionTitleOnly = renderType === 'AccordionTitle';

  const tooltipDisabled =
    !utils.isParticipantCompletedChallenge(participant) &&
    isStepAvailableForComplete &&
    !isUnavailable;

  return (
    <div
      className={`${st(classes.root, {
        mobile: isMobile,
        contentTextAlignment: settings.get(
          challengeSettings.listLayoutContentAlignment,
        ),
        buttonState,
      })} ${className}`}
      onClick={(e) => {
        if (isAccordionTitleOnly && onStepTitleClick) {
          onStepTitleClick(step.id);

          e?.preventDefault();
          e?.stopPropagation();
        }
      }}
    >
      {isAccordion ? (
        <StepsAccordion
          id={`step-${step.id}`}
          key={`step-${step.id}`}
          opened={isStepOpened && !isAccordionTitleOnly}
          forceOpened={isStepForceOpened && !isAccordionTitleOnly}
          className={isAccordionTitleOnly ? classes.isAccordionTitleOnly : ''}
          title={title}
          tooltipText={tooltip}
          prefixIcon={<Icon />}
          align={convertTextToAccordionAlignment(
            settings.get(challengeSettings.listLayoutContentAlignment),
          )}
          tooltipDisabled={tooltipDisabled}
        >
          {isAccordionTitleOnly ? null : !isHidden ? (
            <StepViewBody {...props} />
          ) : (
            <StepViewHidden
              type="List"
              step={step}
              isWeekFixForCTA={isWeekFixForCTA}
              goToCurrentStep={goToCurrentStep}
            />
          )}
        </StepsAccordion>
      ) : !isHidden ? (
        <>
          <h3 className={classes.title}>{title}</h3>
          <StepViewBody {...props} />
        </>
      ) : (
        <StepViewHidden
          type="List"
          step={step}
          isWeekFixForCTA={isWeekFixForCTA}
          goToCurrentStep={goToCurrentStep}
        />
      )}
    </div>
  );
};
StepView.displayName = 'StepView';
StepView.defaultProps = {
  className: '',
  renderType: 'AccordionFull',
  isLastStep: false,
  onStepTitleClick: null,
  onStepResolve: () => {},
  onFeedbackView: () => {},
  onShare: () => {},
  onNextStepClick: () => {},
};
