import React from 'react';

import { ParticipantSection } from '@wix/ambassador-challenge-service-web/types';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import challengeSettings from '../../../settingsParams';
import { convertTextToAccordionAlignment } from '../../../../../services/settingsHelpers';

import { MediaCover } from '../../../../../components-shared/MediaCover';
import { Ricos } from '../../../../../components-shared/Ricos';
import { SectionsAccordion } from '../../../../../components-shared/SectionsAccordion';
import { StepsList } from '../StepsList';

import { st, classes } from './SectionsList.st.css';
import utils from '../../views/utils';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export interface ISectionsListProps {
  t: Function;
  className?: string;
  sectionIdForceOpened?: string;

  isStepsLoading: boolean;
  isParticipantCompletedChallenge: boolean;
  sections: ParticipantSection[];
  onStepTitleClick(id: string): void;
  onNextStepClick?(): void;
}

export const SectionsList: React.FunctionComponent<ISectionsListProps> = (
  props: ISectionsListProps,
) => {
  const {
    t,
    className,
    sectionIdForceOpened,
    isStepsLoading,
    isParticipantCompletedChallenge,
    sections = [],
    onStepTitleClick,
    onNextStepClick,
  } = props;
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  return (
    <ul
      className={`${st(classes.root, {
        mobile: isMobile,
        contentTextAlignment: settings.get(
          challengeSettings.listLayoutContentAlignment,
        ),
      })} ${className || ''}`}
    >
      {sections.map(
        ({ id, source: { settings: sectionSettings }, steps = [] }) => {
          const key = `section-${id}`;
          const resolvedLength = steps.filter((step) =>
            utils.isStepResolved(step),
          ).length;
          const subtitle = t('live.challenges-page.section-steps-subtitle', {
            resolved: resolvedLength,
            total: steps.length,
          });

          return (
            <li key={key} className={classes.section} data-id={id}>
              <SectionsAccordion
                id={key}
                key={key}
                opened={false}
                forceOpened={
                  sectionIdForceOpened && sectionIdForceOpened === id
                }
                title={sectionSettings?.description?.title}
                subtitle={subtitle}
                align={convertTextToAccordionAlignment(
                  settings.get(challengeSettings.listLayoutContentAlignment),
                )}
                className={classes.sectionAccordion}
              >
                <MediaCover
                  media={sectionSettings?.description?.media}
                  className={classes.sectionMedia}
                />

                <Ricos
                  key={id}
                  details={sectionSettings?.description?.details}
                  className={classes.sectionContent}
                />

                <StepsList
                  t={t}
                  steps={steps}
                  isSPC={true}
                  isStepsLoading={isStepsLoading}
                  isParticipantCompletedChallenge={
                    isParticipantCompletedChallenge
                  }
                  renderType="AccordionTitle"
                  onStepTitleClick={onStepTitleClick}
                  stepViewClassName={classes.stepView}
                  onNextStepClick={onNextStepClick}
                />
              </SectionsAccordion>
            </li>
          );
        },
      )}
    </ul>
  );
};
SectionsList.displayName = 'SectionsList';
