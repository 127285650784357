import React from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';
import {
  Button,
  PRIORITY as ButtonPRIORITY,
  SIZE as ButtonSIZE,
} from 'wix-ui-tpa/Button';

import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';
import useLocales from '../../../../../services/hooks/useLocales';
import { Challenges } from '../../../../../editor/types/Experiments';
import utils, { ChallengeEntityType } from '../../views/utils';
import { format } from 'date-fns';
import { useChallengeData } from '../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { useResolveStep } from '../../../../../contexts/ResolveStep/ResolveStepContext';

import { classes, st } from './StepControls.st.css';
import { PreviewActionBlockerContainer } from '../../../../../components-shared/ActionBlocker/PreviewActionBlockerContainer';
import { ActionBlockerAppearance } from '../../../../../components-shared/ActionBlocker/ActionBlocker';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import challengeSettings from '../../../settingsParams';
import { IControl } from './types';

export interface IStepControls extends IControl {
  currentStep: V1ParticipantStep;
  isCompletedChallenge: boolean;
  buttonState: string;
  onStepResolve(currentStep: V1ParticipantStep): Promise<void>;
  onFeedbackView(currentStep: V1ParticipantStep): Promise<void>;
  ShareButton: () => React.ReactNode;
}

export const StepControls: React.FC<IStepControls> = (props) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { language, isMobile } = useEnvironment();
  const locales = useLocales();
  const settings = useSettings();
  const {
    challengeData: { challenge },
  } = useChallengeData();
  const { isResolveStepRequestInProgress } = useResolveStep();

  if (!locales) {
    return null;
  }

  const isShareExpEnabled = experiments.enabled(Challenges.showStepShare);
  const flatSteps = utils.getFlatStepsList({
    listParticipantSections: props.sections,
    participantSteps: props.steps,
  });
  const nextEntity = utils.getNextEntity(props.currentStep?.id, {
    listParticipantSections: props.sections,
    participantSteps: props.steps,
  });
  const isStepAvailableForComplete = utils.isStepAvailableForComplete(
    props.currentStep,
  );
  const isStepLocked = utils.isStepLocked(props.currentStep);

  const formattedStepDate = format(
    new Date(props.currentStep?.dateFrame?.start),
    'PP',
    { locale: locales[language] },
  );

  const isCompleteButtonShown =
    !props.isCompletedChallenge &&
    (isStepAvailableForComplete || utils.isStepOverdue(props.currentStep));

  const isStepUnavailable =
    utils.isStepHidden(
      challenge,
      flatSteps,
      props.currentStep,
      experiments.enabled(Challenges.isHiddenStepsEnabled),
    ) ||
    utils.isVisibleStepLockedForComplete(
      challenge,
      flatSteps,
      props.currentStep,
      experiments.enabled(Challenges.isHiddenStepsEnabled),
    );

  const CompleteButton = isCompleteButtonShown ? (
    <PreviewActionBlockerContainer
      appearance={ActionBlockerAppearance.PopoverAppearance}
      content={t('action-blocker.preview-mode.complete-step')}
      isActionAllowed={utils.isFeedbackFormRequired(props.currentStep)}
    >
      <Button
        className={classes.stepViewMainCTA}
        disabled={isResolveStepRequestInProgress}
        priority={ButtonPRIORITY.primary}
        fullWidth={isMobile}
        size={isMobile ? ButtonSIZE.small : ButtonSIZE.medium}
        onClick={async () => {
          if (!isResolveStepRequestInProgress) {
            await props.onStepResolve(props.currentStep);
          }
        }}
      >
        {t('challenge.page.steps.complete')}
      </Button>
    </PreviewActionBlockerContainer>
  ) : null;

  const NextButton =
    utils.isStepResolved(props.currentStep) &&
    nextEntity.type !== ChallengeEntityType.VOID ? (
      <Button
        className={classes.stepViewMainCTA}
        disabled={isResolveStepRequestInProgress}
        priority={ButtonPRIORITY.primary}
        fullWidth={isMobile}
        size={isMobile ? ButtonSIZE.small : ButtonSIZE.medium}
        onClick={async () => {
          await props.onNextEntity(nextEntity);
        }}
      >
        {t(utils.getNextButtonLabel(nextEntity.type))}
      </Button>
    ) : null;

  const ViewQuestionnaireButton =
    utils.isStepResolved(props.currentStep) &&
    utils.isFeedbackFormRequired(props.currentStep) ? (
      <TextButton
        className={classes.viewQuestionnaire}
        priority={TEXT_BUTTON_PRIORITY.link}
        onClick={async () => {
          if (!isResolveStepRequestInProgress) {
            await props.onFeedbackView(props.currentStep);
          }
        }}
      >
        {t('challenge.page.step.completed.see-feedback')}
      </TextButton>
    ) : null;

  if (isStepUnavailable) {
    return null;
  }

  return (
    <div
      className={st(classes.root, {
        isMobile,
        align: settings.get(challengeSettings.sidebarLayoutTextAlignment),
        buttonState: props.buttonState,
        stepResolved: utils.isStepResolved(props.currentStep),
      })}
    >
      {isStepLocked ? (
        <p className={classes.stepUnavailable}>
          {t('challenge.page.step-unavailable.warning', {
            date: formattedStepDate,
          })}
        </p>
      ) : null}

      {utils.isStepResolved(props.currentStep) ? (
        <>
          {isMobile ? (
            <>
              <p className={classes.stepCompletedText}>
                {t('challenge.page.step.completed')}
              </p>
              {NextButton}
              {ViewQuestionnaireButton}
            </>
          ) : (
            <>
              {NextButton}
              <p className={classes.stepCompletedText}>
                {t('challenge.page.step.completed')}
              </p>
              {ViewQuestionnaireButton}
            </>
          )}
        </>
      ) : null}

      {CompleteButton}
      {utils.isStepResolved(props.currentStep) && isShareExpEnabled
        ? props.ShareButton()
        : null}
    </div>
  );
};
