import React from 'react';
import { LeaveTheChallenge } from '../../components/ChallengesPage/Widget/components/LeaveTheChallenge';
import { BIContext } from '../../contexts/BI/BIContext';
import { ButtonNames } from '../../contexts/BI/interfaces';
import { UserContext } from '../../contexts/User/UserContext';
import { Modal } from '../Modal';

export interface ILeaveChallengeModal {
  isOpen: boolean;
  participantId: string;
  challengeId?: string;
  onClose?(): void;
  onLeave?(): void;
}

export const LeaveChallengeModal: React.FC<ILeaveChallengeModal> = (props) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(props.isOpen);
  const userCtx = React.useContext(UserContext);
  const biCtx = React.useContext(BIContext);

  React.useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        setIsOpen(false);
        props.onClose();
      }}
    >
      <LeaveTheChallenge
        onLeave={async () => {
          await biCtx.memberWebAppButtonClick({
            buttonName: ButtonNames.LeaveTheChallengeAtModal,
          });

          await userCtx.leaveTheChallenge(
            props.participantId,
            props.challengeId,
          );
          setIsOpen(false);
          props.onClose();
          props.onLeave && props.onLeave();
        }}
        onCancel={async () => {
          setIsOpen(false);
          props.onClose();

          await biCtx.memberWebAppButtonClick({
            buttonName: ButtonNames.CancelLeaveTheChallengeModal,
          });
        }}
      />
    </Modal>
  );
};
