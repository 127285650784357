import React from 'react';

import { WixRicosViewer } from '@wix/ricos-viewer';
import { rceUtils } from './rce/rceUtils';
import { getTheme } from '../../selectors/ricosTheme';
import stylesParams from '../../components/ChallengesPage/stylesParams'; // todo: common way

import { GeneralDataContext } from '../../contexts/GeneralDataProvider/GeneralDataContext';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import settingsParams from '../../components/ChallengesPage/settingsParams';
import { ParticipantLayout } from '../../components/ChallengesPage/Settings/challengeSettings/challengeSettings.types';
import { useExperiments } from '@wix/yoshi-flow-editor';

export interface IRicosProps {
  details: any;
  className?: string;
}

export const Ricos: React.FunctionComponent<IRicosProps> = (
  props: IRicosProps,
) => {
  const { details = null, className } = props;
  const { instance, host, defaultBIParams } = React.useContext(
    GeneralDataContext,
  );

  const settings = useSettings();
  const { experiments } = useExperiments();

  const fontKey =
    settings.get(settingsParams.participantLayout) === ParticipantLayout.SIDEBAR
      ? stylesParams.sidebarLayoutBodyDescriptionFont.key
      : stylesParams.listLayoutStepDescriptionFont.key;

  const colorKey =
    settings.get(settingsParams.participantLayout) === ParticipantLayout.SIDEBAR
      ? stylesParams.sidebarLayoutBodyDescriptionColor.key
      : stylesParams.listLayoutStepDescriptionColor.key;

  const bgColorKey =
    settings.get(settingsParams.participantLayout) === ParticipantLayout.SIDEBAR
      ? stylesParams.sidebarLayoutSidebarBackgroundColor.key
      : stylesParams.listLayoutPageBackgroundColor.key;

  return (
    <div className={className || ''}>
      <WixRicosViewer
        wixExperiments={experiments}
        instance={instance}
        theme={getTheme({
          fontKey,
          colorKey,
          host,
          bgColorKey,
        })}
        mediaSettings={{
          disableRightClick: true,
        }}
        plugins={rceUtils.PLUGINS}
        content={JSON.parse(details)}
        biSettings={{
          consumer: 'challenges-ooi-viewer',
          platform: 'Livesite',
          defaultParams: defaultBIParams,
        }}
      />
    </div>
  );
};
Ricos.displayName = 'Ricos';
