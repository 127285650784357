import React from 'react';
import { TranslateFunction } from '@wix/challenges-web-library/dist/src';
import { IUserType } from '../../../../../contexts/User/UserContext';

import { Button } from 'wix-ui-tpa/Button';
import { st, classes } from './JoinButton.st.css';
import { SelectedPaymentOption } from '../Pricing/interfaces';
import {
  JoinFunction,
  CancelJoinRequestFunction,
} from '../../../../../contexts/User/userContextInterface';
import { getJoinButtonText } from './helpers/getJoinButtonText';
import { getJoinClickHandler } from './helpers/getJoinClickHandler';
import { getJoinBIButtonName } from './helpers/getJoinBIButtonName';
import { getJoinButtonProps } from './helpers/getJoinButtonProps';
import { PreviewActionBlockerContainer } from '../../../../../components-shared/ActionBlocker/PreviewActionBlockerContainer';
import { ActionBlockerAppearance } from '../../../../../components-shared/ActionBlocker/ActionBlocker';

export interface IJoinButtonProps {
  t: TranslateFunction;
  id?: string;
  dataHook?: string;
  className?: string;
  disabled: boolean;
  userType: IUserType;
  isAvailableForJoinImmediately: boolean;
  isMobile: boolean;
  buttonState: any;
  join: JoinFunction;
  cancelJoinRequest?: CancelJoinRequestFunction;
  inviteLink: string;
  paymentOption: SelectedPaymentOption | null;
  startDate?: string;
  preJoinValidation?(id: string): string | undefined;
  memberWebAppButtonClick?(props: any): Promise<any>;
  onValidationError?(error: string): void;
}

export const JoinButton: React.FC<IJoinButtonProps> = (
  props: IJoinButtonProps,
) => {
  const {
    t,
    dataHook,
    className,
    disabled,
    userType,
    isAvailableForJoinImmediately,
    isMobile,
    paymentOption,
    buttonState,
    join,
    inviteLink,
    startDate,
    preJoinValidation,
    cancelJoinRequest,
    id,
    memberWebAppButtonClick,
  } = props;

  const resultProps = getJoinButtonProps({
    inviteLink,
    disabled,
    userType,
  });
  const resultContent = getJoinButtonText({
    t,
    isAvailableForJoinImmediately,
    userType,
  });
  const biButtonName = getJoinBIButtonName({
    userType,
  });
  const onClickHandler = getJoinClickHandler({
    joinHandler: async () => {
      await join(paymentOption, startDate);
    },
    cancelHandler: async () => {
      await cancelJoinRequest();
    },
    userType,
  });

  return (
    <div
      className={st(
        classes.root,
        {
          mobile: isMobile,
          disabled: resultProps.disabled,
          buttonState,
        },
        className,
      )}
    >
      <PreviewActionBlockerContainer
        appearance={ActionBlockerAppearance.PopoverAppearance}
      >
        <Button
          {...resultProps}
          className={classes.button}
          fullWidth={isMobile}
          data-hook={dataHook}
          onClick={async () => {
            const validationError = preJoinValidation && preJoinValidation(id);

            if (validationError) {
              console.warn(
                '[challenges] Join validation error',
                validationError,
              );

              props.onValidationError &&
                props.onValidationError(validationError);
              return;
            }

            if (memberWebAppButtonClick) {
              await memberWebAppButtonClick({
                buttonName: biButtonName,
              });
            }

            onClickHandler();
          }}
        >
          {resultContent}
        </Button>
      </PreviewActionBlockerContainer>
    </div>
  );
};
