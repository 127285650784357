import { IResolveStepContext } from '../../../contexts/ResolveStep/ResolveStepContext';
import { IParticipantStepsContext } from '../../../contexts/ParticipantStepsDataProvider/ParticipantStepsContext';
import { IParticipantSectionsContext } from '../../../contexts/ParticipantSections/ParticipantSectionsContext';
import { IGeneralDataProviderProps } from '../../../contexts/GeneralDataProvider/generalDataProviderPropsMap';
import { IUserContext } from '../../../contexts/User/UserContext';
import { ILocationProps } from '../../../contexts/Location/locationProviderPropsMap';
import { ISettingsEventsContext } from '../../../contexts/SettingsEvents/SettingsEvents';

import { BIProviderPropsMap } from '../../../contexts/BI/interfaces';
import { IChallengeSettings } from '../Settings/challengeSettings/challengeSettings.types';
import { UnpackSettingsValues } from '../../../types/helpers';
import { IInviteLinkProviderProps } from '../../../contexts/InviteLink/interfaces';
import { SelectedPaymentOption } from './components/Pricing/interfaces';
import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';
import { ILoadingContext } from '../../../contexts/Loading/interfaces';
import { IToastContext } from '../../../contexts/ToastContext/ToastContext';
import { IChallengeDataProps } from '../../../contexts/ChallengeDataProvider/helpers';

export type IChallengePageSettingsValues = UnpackSettingsValues<IChallengeSettings> & {
  buttonState: 'default' | 'hover';
};

export interface IChallengePageProps
  extends BIProviderPropsMap,
    ILocationProps,
    IUserContext,
    ILoadingContext,
    IGeneralDataProviderProps,
    IChallengeDataProps,
    IParticipantStepsContext,
    IParticipantSectionsContext,
    IResolveStepContext,
    IInviteLinkProviderProps,
    IToastContext,
    ISettingsEventsContext {
  inviteLink: string;
  settings: IChallengePageSettingsValues;
  eligiblePlans: any;
  userPaidPlans: any;
  isLoading: boolean;
  t(key: string, opts?: any): string;
  pageRef?: React.RefObject<HTMLElement>;
  showJoinedToast: boolean;
}

export enum IChallengeJoinRestriction {
  MAX_PARTICIPANTS = 'MAX_PARTICIPANTS',
  SPECIFIC_STARTED = 'SPECIFIC_STARTED',
  FINISHED = 'FINISHED',
}

export interface IChallengePageState {
  wixAdvertisementBannerHeight?: number;
  isError: boolean;
  startDate?: string;
  isStartDateFirstTouch?: boolean;
  selectedTabIndex?: number;
  currentStep?: V1ParticipantStep;
  isCurrentStateWasResolved?: boolean;
  isShareModalOpened?: boolean;
  isFeedbackModalOpened?: boolean;
  isLeaveModalOpened?: boolean;
  isCantJoinModalOpened?: boolean;
  selectedPaymentOption: SelectedPaymentOption | null;
  isChallengeDisabledForJoin?: boolean;
  challengeJoinRestrictions?: IChallengeJoinRestriction[];
  sharedCurrentDateForWeeksSelector?: Date;
  mobileScreen?: 'list' | 'view';
  stepViewAsPageId?: string;
  chosenSectionTileId?: string;
  sectionIdForceOpened?: string; // for ability to open certain section in list layout
  stepIdForceOpened?: string; // for ability to open certain step in list layout
  isParticipantCompletedChallenge?: boolean;
  isDisableEligiblePlans?: boolean;
  savedQuestionnaireData?: any;
}
