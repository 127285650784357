import React from 'react';

import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { isSelfPaced } from '../../../../../selectors/challenges';
import { ChallengeDataContext } from '../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import format from 'date-fns/format';
import useLocales from '../../../../../services/hooks/useLocales';

import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { TextButton } from 'wix-ui-tpa/TextButton';

import { classes, st } from './StepViewHidden.st.css';
import utils from '../../views/utils';

export interface IStepViewHiddenProps {
  className?: string;
  type: 'Sidebar' | 'List';
  step: V1ParticipantStep;
  isWeekFixForCTA?: boolean;
  goToCurrentStep(e): void;
}

export const StepViewHidden: React.FunctionComponent<IStepViewHiddenProps> = ({
  className,
  type,
  step,
  isWeekFixForCTA,
  goToCurrentStep,
}: IStepViewHiddenProps) => {
  const { t } = useTranslation();
  const { isMobile, language } = useEnvironment();
  const { challengeData } = React.useContext(ChallengeDataContext);
  const isSPC = isSelfPaced(challengeData?.challenge);
  const settings = useSettings();
  const alignment = utils.getContentAlignByType(type, settings);

  const { locales, isLocalesLoading } = useLocales();

  if (isLocalesLoading) {
    return null;
  }

  return (
    <div
      className={`${st(classes.root, {
        mobile: isMobile,
        type: type.toLowerCase(),
        alignment,
      })} ${className}`}
    >
      <Text
        tagName="h3"
        typography={TYPOGRAPHY.largeTitle}
        className={classes.title}
      >
        {isSPC
          ? t('live.challenges-page.hidden-step.title-spc')
          : t('live.challenges-page.hidden-step.title-scheduled')}
      </Text>

      <Text
        tagName="p"
        typography={TYPOGRAPHY.runningText}
        className={classes.description}
      >
        {isSPC
          ? t('live.challenges-page.hidden-step.description-spc')
          : t('live.challenges-page.hidden-step.description-scheduled', {
              date: format(new Date(step?.dateFrame?.start), 'EEEE, PP', {
                locale: locales[language],
              }),
            })}
      </Text>

      <TextButton className={classes.ctaLink} onClick={goToCurrentStep}>
        {!isWeekFixForCTA
          ? t('live.challenges-page.hidden-step.cta')
          : t('live.challenges-page.hidden-step.cta-week')}
      </TextButton>
    </div>
  );
};
StepViewHidden.displayName = 'StepViewHidden';
StepViewHidden.defaultProps = {
  className: '',
};
