import React from 'react';

import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';

import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { st, classes } from './StepsListAsTiles.st.css';

import { StepSidebar } from './StepSidebar';

import challengeSettings from '../../../settingsParams';
import { ScheduledStepsList } from './ScheduledStepsList';

export interface IStepsListAsTilesProps {
  steps: V1ParticipantStep[];
  isSPC: boolean;
  currentStepId: string;
  onStepChosen: Function;
}

export const StepsListAsTiles: React.FC<IStepsListAsTilesProps> = (props) => {
  const settings = useSettings();

  const { steps = [], isSPC } = props;

  return (
    <div
      className={st(classes.root, {
        align: settings.get(challengeSettings.listLayoutContentAlignment),
      })}
    >
      {isSPC ? (
        <ul className={classes.stepsList}>
          {steps.map((step, ind) => {
            return (
              <StepSidebar
                key={ind}
                step={step}
                isSPC={props.isSPC}
                currentStepId={props.currentStepId}
                onStepChosen={props.onStepChosen}
              />
            );
          })}
        </ul>
      ) : (
        <ScheduledStepsList
          steps={steps}
          isSPC={props.isSPC}
          currentStepId={props.currentStepId}
          onStepChosen={props.onStepChosen}
        />
      )}
    </div>
  );
};

StepsListAsTiles.displayName = 'StepsListAsTiles';
