export function getWixAdvertisementBannerHeight() {
  return (
    (document?.fullscreenElement &&
      document?.getElementById('WIX_ADS')?.offsetHeight) ||
    0
  );
}

export const setElementToViewport = (element: HTMLElement) => {
  const maximumTopAccordingToWixAdvertisingPanel: number = getWixAdvertisementBannerHeight();
  const windowTop: number = element.getBoundingClientRect().top;
  const scrollTop: number = document?.scrollingElement?.scrollTop;

  if (windowTop < maximumTopAccordingToWixAdvertisingPanel && scrollTop) {
    window.scrollTo({
      top: scrollTop - maximumTopAccordingToWixAdvertisingPanel + windowTop,
      left: 0,
    });
  }
};
