import React from 'react';

import { useTranslation } from '@wix/yoshi-flow-editor';
import { IChallengeJoinRestriction } from '../../interfaces';
import { Notification } from '../../../../../components-shared/Notification';

import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import challengeSettings from '../../../settingsParams';

import { classes, st } from './ChallengeNotification.st.css';

export interface IChallengeNotificationProps {
  className?: string;
  challengeJoinRestrictions: IChallengeJoinRestriction[];
  type: 'Sidebar' | 'List' | 'ListInner';
}

export const ChallengeNotification: React.FunctionComponent<IChallengeNotificationProps> = ({
  className,
  challengeJoinRestrictions,
  type,
}: IChallengeNotificationProps) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const firstRestriction = challengeJoinRestrictions?.[0];

  let restrictionContent = '';

  switch (firstRestriction) {
    case IChallengeJoinRestriction.FINISHED:
      restrictionContent = t('challenge.page.restrictions.finished-already');
      break;
    default:
  }

  return firstRestriction ? (
    <Notification
      className={`${st(classes.root, {
        type: type.toLowerCase(),
      })} ${className}`}
      data-hook="challenge-page-top-notifications"
      alignment={settings.get(challengeSettings.listLayoutHeaderAlignment)}
      withErrorIcon={true}
      content={restrictionContent}
    />
  ) : null;
};
ChallengeNotification.displayName = 'ChallengeNotification';
ChallengeNotification.defaultProps = {
  className: '',
};
