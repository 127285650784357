import React from 'react';
import { withProviders } from '../../../contexts/main/withProviders';
import { withChallengeData } from '../../../contexts/ChallengeDataProvider/withChallengeData';
import { withLocation } from '../../../contexts/Location/withLocation';
import { IThankYouPageProps } from './interfaces';
import { serverTimelineTypeToClientTimelineString } from '@wix/challenges-web-library/dist/src';
import { Pages } from '../../../contexts/Location/LocationContext';
import { getCountPrefix } from '../../../selectors/langCount';
import get from 'lodash/get';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { Button, PRIORITY as ButtonPriority } from 'wix-ui-tpa/Button';

import { classes, st } from './Widget.st.css';
import { withInviteLink } from '../../../contexts/InviteLink/InviteLinkContext';
import { withBI } from '../../../contexts/BI/withBI';
import { ButtonNames } from '../../../contexts/BI/interfaces';
import { applyProviders } from '../../../services/applyProviders';
import { withUser } from '../../../contexts/User/withUser';
import {
  ParticipantState,
  V1AccessType,
  V1Challenge,
} from '@wix/ambassador-challenge-service-web/types';
import { FedopsInteraction } from '../../ChallengesPage/Widget/constants';
import settingsParams from '../../ChallengesPage/settingsParams';
import { getDurationForFlexyTimelines } from '../../../selectors/challenges';
import { setElementToViewport } from '../../../services/scrollHelpers';
import {
  useEnvironment,
  useExperiments,
  useFedopsLogger,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { Challenges } from '../../../editor/types/Experiments';

const ThankYouPage: React.FC<IThankYouPageProps> = (props) => {
  const pageName = 'thank-you-page';
  const settings = useSettings();
  const { interactionEnded } = useFedopsLogger();
  const { experiments } = useExperiments();
  const { t } = useTranslation();
  const [isGoToChallengeClicked, updateIsGoToChallengeClicked] = React.useState(
    false,
  );
  const { isMobile, language } = useEnvironment();

  const wrapperRef = React.useRef();
  React.useEffect(() => {
    const wrapperDOM = wrapperRef?.current;

    setElementToViewport(wrapperDOM);
  }, []);

  React.useEffect(() => {
    const participantState = props?.participant?.transitions?.[0].state;
    if (
      isGoToChallengeClicked &&
      participantState !== ParticipantState.PAYMENT_STARTED
    ) {
      goToChallengePage(challenge);
    } else {
      setTimeout(() => {
        props.updateParticipant();
      }, 200);
    }
  }, [isGoToChallengeClicked, props.participant]);

  React.useEffect(() => {
    interactionEnded(FedopsInteraction.JoinUserToChallenge);
  }, []);

  const goToChallengePage = async (_challenge: V1Challenge) => {
    await props.memberWebAppButtonClick({
      buttonName: ButtonNames.BackToChallenge,
    });
    props.goToPage({
      pageId: Pages.Details,
      challengeId: experiments.enabled(Challenges.enableSlugsInUrl)
        ? _challenge?.settings?.seo?.slug
        : _challenge?.id,
    });
  };

  const {
    challengeData: { challenge },
    inviteLink,
  } = props;
  const isJoined = props.userType !== ParticipantState.JOIN_REQUESTED;
  const duration = getDurationForFlexyTimelines(challenge);

  const langPrefix = getCountPrefix(duration?.value || 0, language);

  const durationString = serverTimelineTypeToClientTimelineString(
    challenge?.settings?.timelineType as any,
    language,
    t,
    'challenge-card.duration-string.ongoing',

    `challenge-card.duration-string.flexible.days${langPrefix}`,

    `challenge-card.duration-string.flexible.weeks${langPrefix}`,
    'challenge-card.duration-string.no-limit',
  );

  return (
    <main
      data-hook={pageName}
      className={st(classes.root, {
        mobile: isMobile,
      })}
    >
      <div className={classes.wrapper} ref={wrapperRef}>
        <h1 className={classes.title} data-hook={`${pageName}-title`}>
          {isJoined
            ? t(`thank-you.page.title`)
            : t(`thank-you.page.secret.title`)}
        </h1>
        {challenge.settings?.accessRestrictions?.accessType ===
          V1AccessType.PRIVATE && (
          <p
            className={classes.description}
            data-hook={`${pageName}-description`}
          >
            {isJoined
              ? t(`thank-you.page.description`)
              : t(`thank-you.page.secret.description`)}
          </p>
        )}

        <div className={classes.contentBox}>
          <h2
            className={classes.contentTitle}
            data-hook={`${pageName}-content-title`}
          >
            {challenge.settings?.description.title}
          </h2>

          <p className={classes.text} data-hook={`${pageName}-text-summary`}>
            {durationString} ·{' '}
            {t(
              `thank-you.page.info.steps${getCountPrefix(
                get(challenge, 'stepsSummary.stepsNumber', 0),
                language,
              )}`,
              {
                count: get(challenge, 'stepsSummary.stepsNumber', 0),
              },
            )}
          </p>

          {isJoined && settings.get(settingsParams.displayOneApp) ? (
            <p
              className={classes.text}
              data-hook={`${pageName}-text-details-link`}
            >
              <a
                href={inviteLink}
                {...{
                  target: '_blank',
                  rel: 'noopener noreferrer',
                }}
                className={classes.link}
              >
                {t('thank-you.page.view-in-app')}
              </a>
            </p>
          ) : (
            ''
          )}

          <Button
            priority={ButtonPriority.primary}
            disabled={isGoToChallengeClicked}
            className={classes.ctaButton}
            data-hook={`${pageName}-cta`}
            fullWidth={false}
            onClick={async (e) => {
              updateIsGoToChallengeClicked(true);
              e && e.preventDefault();
            }}
          >
            {t(isJoined ? 'thank-you.page.back' : 'thank-you.page.return.cta')}
          </Button>
        </div>
      </div>
    </main>
  );
};

ThankYouPage.displayName = 'thank-you-page';

export const ThankYouPageWithProviders: React.FC<any> = applyProviders(
  ThankYouPage,
  [withBI, withInviteLink, withLocation, withChallengeData, withUser],
);

export default applyProviders(ThankYouPageWithProviders, [withProviders]);
