import React from 'react';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { BIContext } from '../../../../../contexts/BI/BIContext';
import { LocationContext } from '../../../../../contexts/Location/LocationContext';
import { InviteLinkContext } from '../../../../../contexts/InviteLink/InviteLinkContext';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import challengeSettings from '../../../settingsParams';
import { ButtonNames } from '../../../../../contexts/BI/interfaces';

import { convertTextAlignmentToPopoverPlacement } from '../../../../../services/settingsHelpers';
import { Dots } from '../Dots';
import { classes, st } from './ChallengeDots.st.css';
import utils from '../../views/utils';

export interface IChallengeDotsProps {
  className?: string;
  type: 'Sidebar' | 'List';
  onLeave: Function;
}

export const ChallengeDots: React.FunctionComponent<IChallengeDotsProps> = ({
  className,
  type,
  onLeave,
}: IChallengeDotsProps) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { memberWebAppButtonClick } = React.useContext(BIContext);
  const { goToExternalUrl } = React.useContext(LocationContext);
  const alignment = utils.getHeaderAlignByType(type, settings);
  const { inviteLink } = React.useContext(InviteLinkContext);

  return (
    <div
      className={`${st(classes.root, {
        mobile: isMobile,
        type: type.toLowerCase(),
        alignment,
      })} ${className}`}
    >
      <Dots
        menuItems={[
          {
            title: t('challenge.page.dots.leave-the-channel'),
            callback: async () => {
              await memberWebAppButtonClick({
                buttonName: ButtonNames.LeaveTheChallenge,
              });

              onLeave();
            },
          },
          {
            title: t('challenge.page.join-via', {
              wixApp: t('challenge.wix-app'),
            }),
            isHidden: !settings.get(challengeSettings.displayOneApp),
            callback: async () => {
              await memberWebAppButtonClick({
                buttonName: ButtonNames.GoToOneApp,
              });

              goToExternalUrl(inviteLink);
            },
          },
        ]}
        popoverPlacement={convertTextAlignmentToPopoverPlacement(alignment)}
        onOpenMenu={async () => {
          await memberWebAppButtonClick({
            buttonName: ButtonNames.OpenDotsMenu,
          });
        }}
      />
    </div>
  );
};
ChallengeDots.displayName = 'ChallengeDots';
ChallengeDots.defaultProps = {
  className: '',
};
