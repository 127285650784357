import React from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { ALIGNMENT, AwardsBadges } from '../Badges';
import { GeneralDataContext } from '../../contexts/GeneralDataProvider/GeneralDataContext';
import { useChallengeData } from '../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { useUser } from '../../contexts/User/UserContext';
import { getEarnedRewards } from '../../selectors/rewards/getEarnedRewards';
import { getCompletionProgress } from '../../selectors/participant/getProgress';
import { Challenges } from '../../editor/types/Experiments';
import { classes, st } from './challengeCompletedModal.st.css';
import { Dialog } from 'wix-ui-tpa/Dialog';
import { useLocalStorage } from '../../contexts/LocalStorage/LocalStorageContext';
import { V1Participant } from '@wix/ambassador-challenge-service-web/types';
import userTypeHandlers from '../../contexts/User/helpers/userTypeHandlers';

export interface IChallengeCompletedModalProps {}

function getLocalStorageCompletedChallengeKey(participant: V1Participant) {
  return `user-already-seen-complete-modal-${participant?.id}`;
}

export const ChallengeCompletedModal: React.FC<IChallengeCompletedModalProps> = () => {
  const { t } = useTranslation();
  const { challengeData } = useChallengeData();
  const { isMobile } = useEnvironment();
  const { participant, userType } = useUser();
  const { experiments } = useExperiments();
  const { setItem, requestItem, storage } = useLocalStorage();
  const [modalAlreadyShowed, setModalAlreadyShowed] = React.useState(true);
  const [opened, setOpened] = React.useState(true);
  const { instance } = React.useContext(GeneralDataContext);

  React.useEffect(() => {
    requestItem(getLocalStorageCompletedChallengeKey(participant));
  }, []);

  React.useEffect(() => {
    if (storage?.[getLocalStorageCompletedChallengeKey(participant)] === null) {
      setModalAlreadyShowed(false);
    }
  }, [storage, setModalAlreadyShowed]);

  if (
    modalAlreadyShowed ||
    !userTypeHandlers.isCompleted(userType) ||
    !experiments.enabled(Challenges.enableHappyScreens)
  ) {
    return null;
  }

  const rewards = getEarnedRewards(
    challengeData?.challenge?.settings?.rewards,
    participant,
  );

  return (
    <Dialog
      notFullscreenOnMobile={true}
      isOpen={opened}
      onClose={() => {
        setItem(getLocalStorageCompletedChallengeKey(participant), true);
        setOpened(false);
      }}
    >
      <section
        className={st(classes.root, {
          mobile: isMobile,
        })}
      >
        <h1 className={classes.title}>
          {t('live.challenges-page.modal.challenge-completed.title')}
        </h1>
        <p className={classes.description}>
          {t('live.challenges-page.modal.challenge-completed.info', {
            progress: `${getCompletionProgress(participant)}%`,
            challengeName: challengeData.challenge.settings.description.title,
          })}
        </p>
        {!!rewards.length && (
          <section className={classes.description}>
            <p className={classes.rewards}>
              {t('live.challenges-page.modal.challenge-completed.reward')}
            </p>
            <AwardsBadges
              alignment={ALIGNMENT.center}
              rewards={rewards}
              instance={instance}
            />
          </section>
        )}
      </section>
    </Dialog>
  );
};
