import React from 'react';

import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';

import { isSelfPaced } from '../../../../../selectors/challenges';
import { ButtonNames } from '../../../../../contexts/BI/interfaces';
import { Challenges } from '../../../../../editor/types/Experiments';

import { Spinner } from '../../../../../components-shared/Spinner';

import { StepsListAsTiles } from '../../components/StepsListAsTiles';

import { IChallengePageProps, IChallengePageState } from '../../interfaces';
import { IWixSDKContext, withExperiments } from '@wix/yoshi-flow-editor';

import { IQuestionnaireSend } from '../../components/Questionnaire/interfaces';
import { ShareButton } from 'wix-ui-tpa/ShareButton';
import { ListLayoutSelectedTab } from '../../../Settings/tabs/Design/components/OverviewScheduleSwitcher/constants';

import { Ricos } from '../../../../../components-shared/Ricos';
import { SectionsListAsTiles } from '../../components/SectionsListAsTiles';
import { MediaCover } from '../../../../../components-shared/MediaCover';
import { ChallengeOverview } from '../../components/ChallengeOverview';
import { StepViewBreadcrumbs } from '../../components/StepViewBreadcrumbs';
import { ToastType } from '../../../../../contexts/ToastContext/interfaces';
import { ChallengeNotification } from '../../components/ChallengeNotification';

import { classes, st } from './SidebarLayoutForParticipant.st.css';
import '../../../../../components-shared/Ricos/rce/rce.global.scss';
import utils, { ChallengeEntityType, INextEntity } from '../utils';
import {
  getWixAdvertisementBannerHeight,
  setElementToViewport,
} from '../../../../../services/scrollHelpers';
import { SideBarStepView } from '../../components/SideBarStepView';
import { OverviewSidebar } from '../../components/OverviewSidebar';
import { ChallengeSummary } from '../../components/ChallengeSummary/ChallengeSummary';
import {
  StepControls,
  SectionControls,
  FinishedNotification,
} from '../../components/StepControls';
import { ShareModal } from '../../components/ShareModal/ShareModal';
import { ChallengeQuestionnaire } from '../../components/ChallengeQuestionnaire';

export interface IExtendWidget {
  isEditor: boolean;
  isMobile: boolean;
  lng: string;
  isFullscreen: boolean;
  experiments: any;
}

class SidebarComponent extends React.Component<
  IChallengePageProps & IWixSDKContext & IExtendWidget,
  IChallengePageState
> {
  static displayName = 'ChallengePage';
  public readonly pageName = 'challenge-page';

  private readonly wrapperRef = null;
  private readonly stepViewRef = null;

  constructor(props: IChallengePageProps & IWixSDKContext & IExtendWidget) {
    super(props);

    const { participant } = this.props;

    this.state = {
      isError: null,
      wixAdvertisementBannerHeight: undefined,
      currentStep: null,
      chosenSectionTileId: null,
      isShareModalOpened: false,
      isFeedbackModalOpened: false,
      selectedPaymentOption: null,
      isParticipantCompletedChallenge: utils.isParticipantCompletedChallenge(
        participant,
      ),
      sharedCurrentDateForWeeksSelector: null,
      mobileScreen: 'list',
      isCurrentStateWasResolved: false,
      savedQuestionnaireData: null,
    };

    this.wrapperRef = React.createRef();
    this.stepViewRef = React.createRef();
  }

  static getDerivedStateFromProps = (
    props: IChallengePageProps & { isEditor: boolean },
  ): Partial<IChallengePageState> => {
    if (props.isEditor) {
      const currentStep = props?.participantSteps?.steps?.[0];
      return {
        currentStep,
      };
    }
  };

  async componentDidMount() {
    const isSPC = isSelfPaced(this.props.challengeData?.challenge);
    const {
      participantSteps: { steps: listParticipantSteps } = {},
      isParticipantStepsLoading,
    } = this.props;

    this.setState({
      wixAdvertisementBannerHeight: getWixAdvertisementBannerHeight(),
    });
    if (isSPC) {
      // We need to re-request all sections and steps for SCP in any case.

      await this.props.updateParticipantSections();
      await this.props.updateParticipantSteps(null, true);
    } else if (
      !isSPC &&
      !listParticipantSteps?.length &&
      !isParticipantStepsLoading
    ) {
      await this.props.updateParticipantSteps(null, true);
    }
  }

  componentDidUpdate(prevProps): void {
    if (
      prevProps.isResolveStepRequestInProgress &&
      !this.props.isResolveStepRequestInProgress
    ) {
      if (!this.props.resolveStepError) {
        this.props.showToast(
          this.props.t(
            !this.state.isCurrentStateWasResolved
              ? 'toast.step-completed'
              : 'toast.step-feedback-updated',
          ),
        );
      } else {
        this.props.showToast(this.props.t('toast.step-completed-error'), {
          type: ToastType.error,
        });
      }
    }
  }

  setStep(currentStep: V1ParticipantStep) {
    this.setState(
      {
        currentStep,
      },
      () => {
        document
          .querySelector(`li[data-id="${currentStep?.id}"]`)
          ?.scrollIntoView({
            behavior: 'smooth',
          });
      },
    );
  }

  onSectionChosen = (chosenSectionTileId: string) => {
    this.scrollToContent();
    this.setState({
      currentStep: null,
      mobileScreen: 'view',
      chosenSectionTileId,
    });
  };

  onStepChosen = (step: V1ParticipantStep): void => {
    this.scrollToContent();
    this.props.memberWebAppButtonClick({
      buttonName: step
        ? ButtonNames.StepTileSelection
        : ButtonNames.OverviewTileSelection,
    });

    this.setState({
      currentStep: step,
      mobileScreen: 'view',
      chosenSectionTileId: null,
    });
  };

  async onNextEntity(nextEntity: INextEntity): Promise<void> {
    switch (nextEntity.type) {
      case ChallengeEntityType.STEP:
        this.onStepChosen(nextEntity.step);
        await this.props.memberWebAppButtonClick({
          buttonName: ButtonNames.NextStep,
        });
        break;
      case ChallengeEntityType.SECTION:
        this.onSectionChosen(nextEntity.section.id);
        await this.props.memberWebAppButtonClick({
          buttonName: ButtonNames.NextSection,
        });
        break;
      default:
        return null;
    }
  }

  private scrollToContent() {
    const { isMobile } = this.props;
    if (
      this.props.experiments.enabled(
        Challenges.enableNewSidebarScrollingBehaviour,
      )
    ) {
      setElementToViewport(this.stepViewRef.current);
    } else {
      this.stepViewRef.current.scrollTop = 0;
    }
    if (isMobile) {
      window?.scrollTo?.(0, 0);
    }
  }

  onFeedbackView = async (step: V1ParticipantStep) => {
    this.setState(
      {
        currentStep: step,
        isFeedbackModalOpened: true,
        savedQuestionnaireData: null,
      },
      async () => {
        await this.props.memberWebAppButtonClick({
          buttonName: ButtonNames.SeeFeedback,
        });
      },
    );
  };

  onStepResolve = async (step: V1ParticipantStep) => {
    this.setState(
      {
        currentStep: step,
        savedQuestionnaireData: null,
      },
      async () => {
        await this.props.memberWebAppButtonClick({
          buttonName: ButtonNames.StepComplete,
        });

        if (utils.isFeedbackFormRequired(step)) {
          this.setState({
            isFeedbackModalOpened: true,
          });
        } else {
          this.resolveStep();
        }
      },
    );
  };

  resolveStep(data: IQuestionnaireSend = {}) {
    const {
      listParticipantSections,
      participantSteps: { steps = [] } = {},
      updateParticipantStepStatus,
      resolveStep,
    } = this.props;

    this.setState(
      {
        isFeedbackModalOpened: false,
      },
      async () => {
        const stepFotView = this.state?.currentStep;

        if (stepFotView?.id) {
          const {
            currentStepAfterUpdate,
            feedbackItems,
            isStepResolved,
          } = utils.getResolveStepData(
            stepFotView,
            data,
            this.state.savedQuestionnaireData,
          );

          this.setState(
            {
              currentStep: currentStepAfterUpdate,
              isCurrentStateWasResolved: utils.isStepResolved(stepFotView),
              savedQuestionnaireData: null,
            },
            async () => {
              /*
                Optimistic Update.
                Change the status of current step and then run resolve request.
                Otherwise the current step can be changed faster then request finished.
               */
              updateParticipantStepStatus(
                listParticipantSections,
                steps,
                currentStepAfterUpdate?.id,
                currentStepAfterUpdate?.transitions,
                currentStepAfterUpdate?.feedback
                  ? currentStepAfterUpdate.feedback.items || []
                  : null,
              );

              await resolveStep(stepFotView.id, feedbackItems, isStepResolved);
            },
          );
        } else {
          console.error("Can't find step for resolve.");
        }
      },
    );
  }

  goToMobileList = () => {
    const { isMobile } = this.props;
    if (isMobile) {
      this.setState(
        {
          mobileScreen: 'list',
        },
        () => {
          // window?.scrollTo?.(0, 0);
        },
      );
    }
  };

  render() {
    const {
      challengeData,
      isLoading,
      isFullWidthLayout,
      experiments,
      settings,
      isMobile,
    } = this.props;

    const { mobileScreen } = this.state;

    return challengeData && challengeData.challenge ? (
      isLoading ? (
        <div className={classes.fullPageSpinner}>
          <Spinner data-hook={`${this.pageName}-spinner`} />
        </div>
      ) : (
        <main
          data-hook={this.pageName}
          className={st(classes.root, {
            mobile: isMobile,
            mobileScreen,
            newScrolling: experiments.enabled(
              Challenges.enableNewSidebarScrollingBehaviour,
            ),
            fullWidthLayout: isFullWidthLayout,
            withoutSpace: settings.sidebarLayoutSpace === 0,
            align: settings.sidebarLayoutTextAlignment,
            fullscreen: this.props.isFullscreen,
          })}
        >
          {this.renderWithChallenge()}
        </main>
      )
    ) : null;
  }

  renderWithChallenge() {
    const { challenge } = this.props.challengeData;
    const isSPC = isSelfPaced(challenge);
    const isSections = isSPC && this.props.listParticipantSections?.length;
    const isSectionActive = this.state.chosenSectionTileId;
    const isStepActive = this.state.currentStep?.id;
    const isFinished =
      this.state.isParticipantCompletedChallenge &&
      this.props.experiments.enabled(Challenges.enableHappyScreens);
    const stickyPositionTop =
      this.state.wixAdvertisementBannerHeight &&
      this.props.experiments.enabled(
        Challenges.enableNewSidebarScrollingBehaviour,
      )
        ? `${this.state.wixAdvertisementBannerHeight}px`
        : undefined;

    return (
      <>
        <ChallengeNotification // no notifications now, saved for future cases
          challengeJoinRestrictions={[]}
          type="Sidebar"
        />

        <div className={classes.wrapper} ref={this.wrapperRef}>
          <div
            style={{
              top: stickyPositionTop,
            }}
            className={`${classes.column} ${classes.sidebar}`}
          >
            <div className={classes.summary}>
              <ChallengeSummary />
            </div>
            <div className={classes.stepsListBox}>
              <OverviewSidebar
                onStepChosen={this.onStepChosen}
                isActive={
                  !this.state.currentStep && !this.state.chosenSectionTileId
                }
              />

              {isSections ? (
                <SectionsListAsTiles
                  chosenSectionTileId={this.state.chosenSectionTileId}
                  currentStepId={this.state.currentStep?.id}
                  onStepChosen={this.onStepChosen}
                  onSectionChosen={this.onSectionChosen}
                />
              ) : (
                this.renderStepsList()
              )}
            </div>
          </div>
          <div
            style={{ width: `${this.props.settings.sidebarLayoutSpace}px` }}
          />
          <div className={`${classes.column} ${classes.contentBody}`}>
            <div className={classes.stepView}>
              <div className={classes.stepViewContent} ref={this.stepViewRef}>
                {isSectionActive ? (
                  this.renderSectionView()
                ) : isStepActive &&
                  this.props.shownTab !== ListLayoutSelectedTab.Overview ? (
                  this.renderStepView()
                ) : (
                  <ChallengeOverview
                    type="Sidebar"
                    challenge={challenge}
                    isGroupInstalled={this.props.isGroupsInstalled}
                    prefix={this.renderBreadcrumbs()}
                  />
                )}
              </div>
            </div>
            {isSectionActive || isStepActive || isFinished ? (
              <div className={classes.stepViewBottom}>
                {isFinished ? <FinishedNotification /> : null}
                {isSectionActive && !isFinished ? (
                  <SectionControls
                    currentSectionId={this.state.chosenSectionTileId}
                    steps={this.props.participantSteps}
                    sections={this.props.listParticipantSections}
                    onNextEntity={(entity) => this.onNextEntity(entity)}
                    onStepChosen={(step) => this.onStepChosen(step)}
                  />
                ) : null}
                {isStepActive && !isFinished ? (
                  <StepControls
                    currentStep={this.state.currentStep}
                    isCompletedChallenge={
                      this.state.isParticipantCompletedChallenge
                    }
                    steps={this.props.participantSteps}
                    sections={this.props.listParticipantSections}
                    buttonState={this.props.buttonState}
                    onStepResolve={(step) => this.onStepResolve(step)}
                    onNextEntity={(entity) => this.onNextEntity(entity)}
                    onFeedbackView={(step) => this.onFeedbackView(step)}
                    ShareButton={() => this.renderShareButton()}
                  />
                ) : null}
              </div>
            ) : null}
          </div>

          {this.state.currentStep ? (
            <ShareModal
              step={this.state.currentStep}
              isOpened={this.state.isShareModalOpened}
              onClose={() => {
                this.setState({
                  isShareModalOpened: false,
                });
              }}
            />
          ) : null}

          {this.state.currentStep ? (
            <ChallengeQuestionnaire
              isOpened={this.state.isFeedbackModalOpened}
              currentStep={this.state.currentStep}
              savedQuestionnaireData={this.state.savedQuestionnaireData}
              onSaveIntermediateData={(type, data) => {
                this.setState({
                  savedQuestionnaireData: {
                    [type]: data || {},
                  },
                });
              }}
              onResolve={(data) => {
                this.resolveStep(data);
              }}
              onCancel={() => {
                this.setState({
                  isFeedbackModalOpened: false,
                  savedQuestionnaireData: null,
                });
              }}
            />
          ) : null}
        </div>
      </>
    );
  }

  renderStepsList() {
    const {
      challengeData: { challenge },
      participantSteps: { steps = [] },
      isParticipantStepsLoading,
    } = this.props;
    const { currentStep } = this.state;

    if (isParticipantStepsLoading) {
      return null;
    }

    return (
      <StepsListAsTiles
        steps={steps}
        isSPC={isSelfPaced(challenge)}
        currentStepId={currentStep?.id}
        onStepChosen={this.onStepChosen}
      />
    );
  }

  renderSectionView() {
    const {
      listParticipantSections = [],
      isListParticipantSectionsRequestInProgress,
    } = this.props;
    const { chosenSectionTileId } = this.state;
    const section = listParticipantSections.find(
      (s) => s.id === chosenSectionTileId,
    );

    return isListParticipantSectionsRequestInProgress ? (
      <Spinner />
    ) : (
      <div id={chosenSectionTileId}>
        {this.renderBreadcrumbs()}

        <h3 className={classes.stepViewTitle}>
          {section?.source?.settings?.description?.title}
        </h3>

        <MediaCover
          media={section?.source?.settings?.description?.media}
          className={classes.sectionMedia}
        />

        <div className={classes.sectionDescription}>
          <Ricos
            key={section?.id}
            details={section?.source?.settings?.description?.details}
          />
        </div>
      </div>
    );
  }

  renderStepView() {
    const {
      challengeData: { challenge },
      isListParticipantSectionsRequestInProgress,
      listParticipantSections,
      isParticipantStepsLoading,
      participantSteps,
    } = this.props;

    return (
      <SideBarStepView
        participantSteps={participantSteps}
        listParticipantSections={listParticipantSections}
        challenge={challenge}
        isListParticipantSectionsRequestInProgress={
          isListParticipantSectionsRequestInProgress
        }
        goToCurrentStep={() => {
          this.setStep(
            utils.getFirstAvailableStep(utils.getFlatStepsList(this.props)),
          );
        }}
        isParticipantStepsLoading={isParticipantStepsLoading}
        currentStep={this.state.currentStep}
        Breadcrumbs={() => this.renderBreadcrumbs()}
        pageRef={this.props.pageRef}
      />
    );
  }

  renderBreadcrumbs() {
    const { listParticipantSections } = this.props;
    const { currentStep } = this.state;

    return (
      <StepViewBreadcrumbs
        sections={listParticipantSections}
        currentStep={currentStep}
        goToMobileList={this.goToMobileList}
        onSectionChosen={this.onSectionChosen}
      />
    );
  }

  renderShareButton() {
    const {
      t,
      location: { url },
      isResolveStepRequestInProgress,
    } = this.props;

    return (
      <ShareButton
        className={classes.shareButton}
        shareData={{
          title: t('social-share.title'),
          url,
        }}
        onClick={(sharePromise) => {
          if (!sharePromise && !isResolveStepRequestInProgress) {
            this.setState(
              {
                isShareModalOpened: true,
              },
              async () => {
                await this.props.memberWebAppButtonClick({
                  buttonName: ButtonNames.ShareCompletedStep,
                });
              },
            );
          }
        }}
        withIcon
      />
    );
  }
}

export default withExperiments<any>(SidebarComponent);
