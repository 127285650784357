import {
  ChallengeReward,
  Trigger,
  V1Participant,
} from '@wix/ambassador-challenge-service-web/types';

export function getEarnedRewards(
  rewards?: ChallengeReward[],
  participant?: V1Participant,
) {
  const stepsSummary = participant?.stepsSummary;
  if (!rewards.length || !stepsSummary) {
    return [];
  }
  const { stepsNumber, completedStepsNumber } = stepsSummary;
  return rewards.filter(({ trigger }) => {
    switch (trigger) {
      case Trigger.ALL_STEPS_COMPLETED:
        return stepsNumber === completedStepsNumber;
      case Trigger.JOINED_TO_CHALLENGE:
        return true;
      case Trigger.STEP_COMPLETED:
        return completedStepsNumber > 0;
      default:
        return false;
    }
  });
}
