import React from 'react';
import {
  IChallengeJoinRestriction,
  IChallengePageProps,
  IChallengePageSettingsValues,
  IChallengePageState,
} from '../../interfaces';

import { IWixSDKContext, withExperiments } from '@wix/yoshi-flow-editor';

import {
  getChallengePaidPlans,
  IImageData,
  imageDataToMediaUrl,
  isChallengeFree,
  serverTimelineTypeToClientTimelineString,
} from '@wix/challenges-web-library/dist/src';
import {
  ParticipantState,
  V1Challenge,
  V1DurationUnit,
} from '@wix/ambassador-challenge-service-web/types';
import { getCountPrefix } from '../../../../../selectors/langCount';

import { JoinButton } from '../../components/JoinButton';
import { Spinner } from '../../../../../components-shared/Spinner';
import UserBox from '../../../../../components-shared/UserBox';
import { DateInput } from '../../../../../components-shared/DateInput';
import { Notification } from '../../../../../components-shared/Notification';

import { st, classes } from './VisitorPage.st.css';
import { UserState } from '../../../../../contexts/User/UserContext';
import { ChallengeDetails } from '../../../../../components-shared/ChallengeDetails';
import { PageSection } from '../../../../../components-shared/PageSection';
import { Pricing } from '../../components/Pricing/Pricing';
import { RewardInfo } from '../../../../../components-shared/RewardInfo';
import userTypeHandlers from '../../../../../contexts/User/helpers/userTypeHandlers';
import {
  isNeedToSelectStartDate,
  isPricingPickingForbidden,
  isFlexible,
  isSelfPaced,
  getSelfPacedSettings,
  getDurationForFlexyTimelines,
} from '../../../../../selectors/challenges';
import { ActionTypes } from '../../../../../contexts/BI/interfaces';
import { format } from 'date-fns';
import { SocialGroup } from '../../../../../components-shared/SocialGroup';
import {
  getNearestStartDateFromFutureEligiblePPs,
  isChallengeHasEligiblePPsInFutureOnly,
  isWithEligiblePPsAfterChallengeStartDate,
} from '../../components/Pricing/helpers/getChallengeEligiblePaidPlans';
import { CantJoinModal } from '../../components/CantJoinModal';

export enum JoinButtonIds {
  Top = 'Top',
  Bottom = 'Bottom',
}

const START_DATE_ERROR = 'Start day must be filled';
const FUTURE_PLANS_ONLY_ERROR =
  'The challenge have paid plans with start date in the future.';

export interface IExtendVisitorPage {
  isMobile: boolean;
  lng: string;
  experiments: any;
}

class VisitorPageComponent extends React.PureComponent<
  IChallengePageProps & IWixSDKContext & IExtendVisitorPage,
  IChallengePageState
> {
  static displayName = 'ChallengePage';
  public readonly pageName = 'challenge-page';
  public readonly translationName = 'challenge.page';
  private readonly dateInputRef = null;
  private readonly footerRef = null;

  constructor(
    props: IChallengePageProps & IWixSDKContext & IExtendVisitorPage,
  ) {
    super(props);

    this.state = {
      selectedPaymentOption: null,
      isError: false,
      startDate: format(this.getStartMinDate(), 'dd/MM/yyyy'),
      isStartDateFirstTouch: false, // some sort of hack, we need to set the error state for start date for the first attempt to join with empty value
      challengeJoinRestrictions: [],
      isCantJoinModalOpened: false,
      isDisableEligiblePlans: false,
    };

    this.dateInputRef = React.createRef();
    this.footerRef = React.createRef();
    this.scrollToFooter = this.scrollToFooter.bind(this);

    this.checkRestrictions();
  }

  getStartMinDate() {
    const { challengeData, eligiblePlans, userPaidPlans } = this.props;
    const challengeId = challengeData?.challenge?.id;

    return isChallengeHasEligiblePPsInFutureOnly(
      eligiblePlans,
      userPaidPlans,
      challengeId,
    )
      ? getNearestStartDateFromFutureEligiblePPs(
          eligiblePlans,
          userPaidPlans,
          challengeId,
        )
      : new Date();
  }

  checkRestrictions() {
    const { participant } = this.props;
    const participantsNumber = this.props.challengeData?.challenge
      ?.participantsSummary?.participantsNumber;
    const maxParticipants = this.props.challengeData?.challenge?.settings
      ?.accessRestrictions?.maxParticipants;

    if (
      !participant?.id &&
      participantsNumber &&
      maxParticipants &&
      !isNaN(participantsNumber) &&
      !isNaN(maxParticipants) &&
      participantsNumber >= maxParticipants
    ) {
      this.state.challengeJoinRestrictions.push(
        IChallengeJoinRestriction.MAX_PARTICIPANTS,
      ); // ! Called from constructor.
    }
  }

  /**
   For the flexible challenge with empty start date we need to set error state for the start date picker on join attempt.
   For the free challenge we need just to start join process both for the top and bottom buttons.
   For the paid challenge we need to scroll to bottom on click on the top button.
   */
  validation = (id: string): string | undefined => {
    const {
      challengeData: { challenge },
      userType,
      userPaidPlans,
      eligiblePlans,
    } = this.props;
    const { startDate } = this.state;
    const challengePaidPlans = getChallengePaidPlans(
      challenge.id,
      userPaidPlans,
    );

    if (
      isNeedToSelectStartDate(challenge, userType, userPaidPlans) &&
      !startDate
    ) {
      this.setState({
        isStartDateFirstTouch: true,
      });
      if (id === JoinButtonIds.Top) {
        this.scrollToFooter();
      }
      return START_DATE_ERROR;
    }

    if (
      id === JoinButtonIds.Top &&
      (isNeedToSelectStartDate(challenge, userType, userPaidPlans) ||
        !isChallengeFree(
          challenge?.settings?.pricing,
          challengePaidPlans.length,
        ))
    ) {
      this.scrollToFooter();
      return 'Top button just scrolls to footer';
    }

    if (
      isWithEligiblePPsAfterChallengeStartDate(
        eligiblePlans,
        userPaidPlans,
        challenge,
      ) &&
      !this.state.isDisableEligiblePlans
    ) {
      if (id === JoinButtonIds.Top) {
        this.scrollToFooter();
      }
      return FUTURE_PLANS_ONLY_ERROR;
    }
  };

  onStartDateChange = (value: any) => {
    this.setState(
      {
        isError: false,
        startDate: value ? format(new Date(value), 'dd/MM/yyyy') : null,
      },
      async () => {
        await this.props.afterAction(
          ActionTypes.START_DATE_SELECTED,
          value ? new Date(value).toISOString() : undefined,
        );
      },
    );
  };

  render() {
    const { challengeData, isLoading, settings } = this.props;

    return challengeData && challengeData.challenge ? (
      <main
        data-hook={this.pageName}
        className={st(classes.root, {
          mobile: this.isMobile(),
          headerTextAlignment: settings.headerTextAlignment,
          imageRatio: settings.imageRatio,
          cropSelection: settings.cropSelection,
          contentTextAlignment: settings.contentTextAlignment,
        })}
      >
        {isLoading && (
          <div
            className={classes.loader}
            data-hook={`${this.pageName}-spinner`}
          >
            <Spinner />
          </div>
        )}
        {this.renderWithChallenge()}
      </main>
    ) : null;
  }

  renderWithChallenge() {
    return (
      <>
        {this.renderNotifications()}
        {this.renderMedia(true)}
        {this.renderHeader()}
        {this.renderMedia(false)}
        {this.renderContent()}
        {this.renderStartDateSelection()}
        {this.renderPricing()}
        {this.renderFooter()}
        {this.renderCantJoinModal()}
      </>
    );
  }

  renderNotifications() {
    const { t, userType } = this.props;
    const { challengeJoinRestrictions } = this.state;
    const firstRestriction =
      challengeJoinRestrictions && challengeJoinRestrictions[0];
    let restrictionContent = '';

    switch (firstRestriction) {
      case IChallengeJoinRestriction.MAX_PARTICIPANTS:
        restrictionContent = t('challenge.page.restrictions.max-participants');

        break;
      case IChallengeJoinRestriction.SPECIFIC_STARTED:
        restrictionContent = t('challenge.page.restrictions.specific-started');

        break;
      default:
    }

    switch (userType) {
      case ParticipantState.JOIN_REQUESTED:
        restrictionContent = t('challenge.join-request.notification');
        break;
      default:
    }

    return firstRestriction || restrictionContent ? (
      <Notification
        className={classes.topNotification}
        dataHook={`${this.pageName}-top-notifications`}
        alignment={this.props.settings.headerTextAlignment}
        withErrorIcon={true}
        content={restrictionContent}
      />
    ) : null;
  }

  renderMedia(isForMobile) {
    const {
      challengeData: { challenge },
      settings,
    } = this.props;

    const isShouldBeRendered =
      settings.displayHeaderImage &&
      challenge.settings.description.media &&
      ((this.isMobile() && isForMobile) || (!this.isMobile() && !isForMobile));

    return isShouldBeRendered ? (
      <figure
        className={classes.imageWrapper}
        data-hook={
          isForMobile
            ? `${this.pageName}-mobile-image-wrapper`
            : `${this.pageName}-image-wrapper`
        }
      >
        <div className={classes.imageRatioBox}>
          <div
            className={classes.image}
            style={{
              backgroundImage: `url(${imageDataToMediaUrl({
                ...(challenge.settings.description.media.image as IImageData),
                width: isForMobile ? 500 : 1000,
                height: isForMobile ? 400 : 800,
              })})`,
            }}
          />
        </div>
      </figure>
    ) : null;
  }

  renderHeader() {
    const {
      t,
      lng,
      challengeData: { challenge, isAvailableForJoinImmediately },
      inviteLink,
      userType,
      userPaidPlans,
      join,
      settings,
      cancelJoinRequest,
    } = this.props;

    const {
      showHeaderDate,
      showOverview,
      showDuration,
      showParticipants,
      showSteps,
      overviewItemsCount,
      stepsCount,
    } = this.getBaseViewOptions(challenge, settings);
    const duration = getDurationForFlexyTimelines(challenge);

    const timelineLangPrefix = getCountPrefix(duration?.value || 0, lng);
    const durationString = serverTimelineTypeToClientTimelineString(
      challenge.settings.timelineType as any,
      lng,
      t,
      'challenge-card.duration-string.ongoing',
      `challenge-card.duration-string.flexible.days${timelineLangPrefix}`,
      `challenge-card.duration-string.flexible.weeks${timelineLangPrefix}`,
      'challenge-card.duration-string.no-limit',
    );

    const durationLangPrefix = getCountPrefix(duration?.value || 0, lng);
    const stepsCountPrefix = getCountPrefix(stepsCount, lng);
    const participantsCountPrefix = getCountPrefix(
      challenge.participantsSummary.participantsNumber,
      lng,
    );

    return (
      <header className={classes.header}>
        <div className={classes.headerInner}>
          {showHeaderDate ? (
            <p
              className={classes.headerDateInfo}
              data-hook={`${this.pageName}-date`}
            >
              {durationString}
            </p>
          ) : null}

          <h1
            className={classes.headerTitle}
            data-hook={`${this.pageName}-title`}
          >
            {(challenge as any)?.shouldTranslateTitle
              ? t(challenge.settings.description.title)
              : challenge.settings.description.title}
          </h1>

          {showOverview ? (
            <ul
              style={{
                gridTemplateColumns: `repeat(${overviewItemsCount}, 135px)`,
              }}
              className={classes.detailsList}
              data-hook={`${this.pageName}-details-list`}
            >
              {showDuration
                ? this.renderDetailsItem(
                    duration?.value,
                    duration?.unit === V1DurationUnit.DAYS
                      ? t(
                          `${this.translationName}.details.days${durationLangPrefix}`,
                        )
                      : t(
                          `${this.translationName}.details.weeks${durationLangPrefix}`,
                        ),
                    `${this.pageName}-details-item-duration`,
                  )
                : null}

              {showSteps
                ? this.renderDetailsItem(
                    stepsCount,
                    t(
                      `${this.translationName}.details.steps${stepsCountPrefix}`,
                    ),
                    `${this.pageName}-details-item-steps`,
                  )
                : null}

              {showParticipants
                ? this.renderDetailsItem(
                    challenge.participantsSummary.participantsNumber,
                    t(
                      `${this.translationName}.details.participants${participantsCountPrefix}`,
                    ),
                    `${this.pageName}-details-item-participants`,
                  )
                : null}
            </ul>
          ) : null}

          <RewardInfo
            className={classes.rewards}
            rewards={challenge.settings.rewards}
            icon={true}
          />

          {!this.state.challengeJoinRestrictions.length ? (
            <JoinButton
              id={JoinButtonIds.Top}
              paymentOption={this.state.selectedPaymentOption}
              t={t}
              disabled={this.state.isError}
              userType={userType}
              isAvailableForJoinImmediately={isAvailableForJoinImmediately}
              isMobile={this.isMobile()}
              buttonState={this.props.buttonState || settings.buttonState}
              join={join}
              cancelJoinRequest={cancelJoinRequest}
              inviteLink={inviteLink}
              startDate={
                isNeedToSelectStartDate(challenge, userType, userPaidPlans)
                  ? this.state.startDate
                  : null
              }
              preJoinValidation={this.validation}
              memberWebAppButtonClick={this.props.memberWebAppButtonClick}
            />
          ) : null}
        </div>
      </header>
    );
  }

  renderDetailsItem(
    value: string | number,
    description: string,
    dataHook: string,
  ) {
    const { settings } = this.props;

    return (
      <li
        className={`${classes.detailsListItem} ${
          !settings.displayDivider && classes.detailsListItemNoDivider
        }`}
        data-hook={dataHook}
      >
        <span className={classes.detailsListItemValue}>{value}</span>
        <span className={classes.detailsListItemDescription}>
          {description}
        </span>
      </li>
    );
  }

  renderContent() {
    const {
      t,
      challengeData: { challenge },
      settings,
      isMobile,
    } = this.props;

    return (
      <article
        className={classes.content}
        data-hook={`${this.pageName}-content`}
      >
        <PageSection title={t(`${this.translationName}.content-title`)}>
          {challenge.settings.description.details ? (
            <div className={classes.contentDescriptionDetails}>
              <ChallengeDetails
                isMobile={isMobile}
                details={challenge.settings.description.details}
              />
            </div>
          ) : null}
          {settings.displayOneApp && (
            <p className={classes.contentDescription}>
              {t('challenge.description.requires-one-app')}{' '}
              <a
                href={this.props.inviteLink}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                {t('challenge.description.mobile-app')}
              </a>
            </p>
          )}

          <SocialGroup
            isGroupInstalled={this.props.isGroupsInstalled}
            className={classes.group}
            instance={this.props.instance}
            title={t('live-site.groups.section-title')}
            subTitle={t('live-site.groups.section-subtitle.not-joined')}
            groupId={challenge?.settings?.socialGroupId}
            showButton={false}
          />

          {settings.displayOwner ? (
            <UserBox
              imageUrl={challenge.owners[0].imageUrl}
              fullName={challenge.owners[0].fullName}
              dataHook={`${this.pageName}-author`}
              alignment={settings.contentTextAlignment}
            />
          ) : null}
        </PageSection>
      </article>
    );
  }

  renderStartDateSelection() {
    const {
      t,
      challengeData: { challenge },
      userType,
      userPaidPlans,
      settings,
    } = this.props;

    if (
      isNeedToSelectStartDate(challenge, userType, userPaidPlans) &&
      !this.state.challengeJoinRestrictions.length
    ) {
      return (
        <PageSection
          className={classes.startDate}
          title={t(`${this.translationName}.start-date-selection.title`)}
          titleDataHook={`${this.pageName}-start-date-selection-title`}
          dataHook={`${this.pageName}-start-date-selection`}
        >
          <label
            ref={this.dateInputRef}
            id="date-input"
            className={classes.startDateSelectionDescription}
          >
            {t(`${this.translationName}.start-date-selection.description`)}
          </label>

          <DateInput
            className={classes.startDateSelectionInput}
            defaultValue={this.state.startDate}
            showError={this.state.isError}
            textAlignment={settings.contentTextAlignment}
            errorMessage={t(
              `${this.translationName}.start-date-selection.future-date-error`,
            )}
            onChange={this.onStartDateChange}
            minDate={this.getStartMinDate()}
          />
        </PageSection>
      );
    }

    return null;
  }

  renderPricing() {
    const {
      challengeData: { challenge },
      userType,
      userPaidPlans,
    } = this.props;
    const { selectedPaymentOption, challengeJoinRestrictions } = this.state;

    return (
      <Pricing
        className={classes.pricingSection}
        disabled={
          !!challengeJoinRestrictions.length ||
          isPricingPickingForbidden(challenge, userType, userPaidPlans)
        }
        disabledEligible={this.state.isDisableEligiblePlans}
        selectedPaymentOption={selectedPaymentOption}
        onPaymentSelected={(selectedOption) => {
          this.setState({ selectedPaymentOption: selectedOption });
        }}
      />
    );
  }

  renderFooter() {
    const {
      t,
      challengeData: { challenge, isAvailableForJoinImmediately },
      inviteLink,
      userType,
      promptLogin,
      userPaidPlans,
      join,
      settings,
      cancelJoinRequest,
    } = this.props;
    const { startDate } = this.state;

    if (userTypeHandlers.isJoinedAlready(userType)) {
      return null;
    }

    return !this.state.challengeJoinRestrictions.length ? (
      <div className={classes.footer} ref={this.footerRef}>
        <JoinButton
          id={JoinButtonIds.Bottom}
          t={t}
          paymentOption={this.state.selectedPaymentOption}
          dataHook={`${this.pageName}-cta-button`}
          disabled={this.state.isError}
          userType={userType}
          isAvailableForJoinImmediately={isAvailableForJoinImmediately}
          isMobile={this.isMobile()}
          buttonState={this.props.buttonState || settings.buttonState}
          join={join}
          cancelJoinRequest={cancelJoinRequest}
          inviteLink={inviteLink}
          startDate={
            isNeedToSelectStartDate(challenge, userType, userPaidPlans)
              ? startDate
              : null
          }
          preJoinValidation={this.validation}
          memberWebAppButtonClick={this.props.memberWebAppButtonClick}
          onValidationError={(error) => {
            if (error === START_DATE_ERROR) {
              this.setState({ isError: true });
            }
            if (error === FUTURE_PLANS_ONLY_ERROR) {
              this.setState({ isCantJoinModalOpened: true });
            }
          }}
        />

        {userType === UserState.VISITOR ? (
          <p>
            {t(`${this.translationName}.already-a-member`)}{' '}
            <a
              href="#"
              className={classes.link}
              onClick={async (e) => {
                e && e.preventDefault();

                await promptLogin();
              }}
            >
              {t('challenge.login')}
            </a>
          </p>
        ) : null}
      </div>
    ) : null;
  }

  renderCantJoinModal() {
    const { isCantJoinModalOpened } = this.state;

    return (
      <CantJoinModal
        isOpen={isCantJoinModalOpened}
        onSecondaryLinkClick={() => {
          this.setState({
            isDisableEligiblePlans: true,
            isCantJoinModalOpened: false,
          });
        }}
        onClose={() => {
          this.setState({
            isCantJoinModalOpened: false,
          });
        }}
      />
    );
  }

  isMobile() {
    return this.props.isMobile;
  }

  getBaseViewOptions(
    challenge: V1Challenge,
    settings: IChallengePageSettingsValues,
  ) {
    const showHeaderDate = !isFlexible(challenge) && !isSelfPaced(challenge);

    const stepsCount = challenge?.stepsSummary?.stepsNumber;
    const showSteps = settings.displayChallengeSteps && !!stepsCount;
    const showDuration =
      settings.displayChallengeDuration &&
      (isFlexible(challenge) ||
        (isSelfPaced(challenge) && getSelfPacedSettings(challenge)?.duration));
    const showParticipants =
      settings.displayChallengeParticipants &&
      !!challenge.participantsSummary.participantsNumber;

    const showOverview = showParticipants || showDuration || showSteps;
    const overviewItemsCount =
      Number(showSteps) + Number(showDuration) + Number(showParticipants);

    return {
      showHeaderDate,
      stepsCount,
      showSteps,
      showDuration,
      showParticipants,
      showOverview,
      overviewItemsCount,
    };
  }

  scrollToFooter() {
    if (window?.scrollTo && this.footerRef?.current) {
      const footerOffsetTop = ((this.footerRef.current.getBoundingClientRect()
        .top as number) + (window.scrollY || window.pageYOffset)) as number;
      const windowHeight = window?.innerHeight ? window.innerHeight - 200 : 600;

      window.scrollTo({
        top: footerOffsetTop - windowHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
}

export const VisitorPage = withExperiments(VisitorPageComponent);
