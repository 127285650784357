import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import challengeSettings from '../../../settingsParams';

import { classes, st } from './StepControls.st.css';

export const FinishedNotification: React.FC = () => {
  const { t } = useTranslation();
  const settings = useSettings();
  return (
    <div
      className={st(classes.root, {
        align: settings.get(challengeSettings.sidebarLayoutTextAlignment),
      })}
    >
      <p className={classes.finishedNotification}>
        {t('challenge.page.restrictions.finished-already')}
      </p>
    </div>
  );
};
